import React from 'react'
import { FaHome } from 'react-icons/fa';
import styled from 'styled-components'
import { Link } from 'gatsby';
import * as palette from '../styles/variables';

const NavList = styled.ul`
    margin: 0;

    li {
        list-style-type: none;
        cursor: pointer;

        a {
            text-decoration: none;
            display: block;
        }

    }
`;

const activeStyles = {
    color: palette.magenta
}

const Nav = () => {
    return (
        <NavList>
            <li className="navItem">
                <Link to="/" activeStyle={activeStyles}><FaHome /></Link>
            </li>
            <li className="navItem">
                <Link to='/platform/' activeStyle={activeStyles}>Platform</Link>
            </li>
            <li className="navItem">
                <Link to='/poker/' activeStyle={activeStyles}>Poker</Link>
            </li>
            {/* <li className="navItem">
                <Link to='/casino/' activeStyle={activeStyles}>Casino</Link>
            </li> */}
            <li className="navItem">
                <a href="http://news.cubeia.com/" target="_blank" rel="noopener noreferrer">News</a>
            </li>
            <li className="navItem">
                <Link to='/contact/' activeStyle={activeStyles}>Contact</Link>
            </li>
      </NavList>
    )
}

export default Nav