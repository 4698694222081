import React from 'react'
import { Link } from "gatsby"
import logo from '../images/Cubeia_logo-white.png'



const Brand = () => {
    return (
        <Link to="/" style={{ display: 'block' }} className="logoWrap brands">
            <img src={logo} alt="Cubeia logo" />
        </Link>
    )
}

export default Brand