import PropTypes from "prop-types"
import React, { Component } from "react"
import Brand from './brand'
import Nav from './nav'
import BurgerAnimate from './burgerAnimate'
import {HeaderStyled, LogoStyled, NavStyled, HeadWrapStyled} from './headerStyled'


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClickedBurger: false,
      isScrolled: false
     };

    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    let scroll = false
    if(winScroll !== 0) {
      scroll = true
    }
    this.setState({ isScrolled: scroll })
  }
  handleClick() {
    this.setState({ isClickedBurger: !this.state.isClickedBurger })
  }

  render () {
    const { isScrolled } = this.state
    return (
        <HeadWrapStyled>
          <HeaderStyled isClickedBurger={this.state.isClickedBurger} scrollClass={isScrolled}>
            <LogoStyled scrollClass={isScrolled}>
              <Brand />
              <BurgerAnimate isClickedBurger={this.state.isClickedBurger} onClick={this.handleClick} />
            </LogoStyled>
            <NavStyled>
              <Nav />
            </NavStyled>
          </HeaderStyled>
      </HeadWrapStyled>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
