import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device } from '../styles/device';




export const LayoutStyled = styled.div`
    main {
        margin-top: ${palette.headerHeight};

        @media ${device.tablet} {
            margin-top: ${palette.headerHeight};
        }
    }
`;