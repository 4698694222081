import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device } from '../styles/device';


export const HeadWrapStyled = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;
`;

export const HeaderStyled = styled.header`
    width: 100%;
    background-color: ${palette.black};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

    @media ${device.tablet} {
        padding-left: 50px;
        padding-right: 50px;
        flex-direction: row;
        height: ${palette.headerHeight};
        /* border-bottom: ${props => props.scrollClass ? 'none' : `1px solid ${palette.lightGreyBorder}`}; */
        background-color: ${props => props.scrollClass ? palette.black : 'black'};
        box-shadow:  ${props => props.scrollClass ? '0 0 20px rgba(0,0,0,.3)' : 'none'};
    }

    ul {
        display: block;
        overflow: hidden;
        /* transition: height .4s ease,margin .4s ease,opacity .2s .2s ease; */
        width: 100%;
        padding-left: 0;
        height: ${props => props.isClickedBurger ? 'auto' : '0'};

            li {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            li:not(:last-child) {
                border-bottom: 1px solid ${palette.lightGreyBorder};

                @media ${device.tablet} {
                    border-bottom: none;
                    margin-right: 24px;
                }
            }

            li a {
                color: ${palette.white};
                font-weight: ${palette.fontBold};
                font-size: ${palette.fontSizeXs};
                text-transform: uppercase;
            }

            li a svg {
                font-size: ${palette.fontSizeMd};
                margin-bottom: -3px;
            }


        @media ${device.tablet} {
            display: flex;
            height: auto;
        }
    }

`;

export const LogoStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: none;
    padding-top: 20px;
    padding-right: 0;
    padding-bottom: 20px;
    transition: height .4s ease,margin .4s ease,opacity .2s .2s ease;

    .logoWrap {
        width: 90px;
        margin-bottom: 5px;
    }

    @media ${device.tablet} {
        /* border-right: ${props => props.scrollClass ? 'none' : `1px solid ${palette.lightGreyBorder}`}; */
        max-width: 140px;
        padding-right: 40px;
    }
`;

export const NavStyled = styled.div`
    display: flex;
    align-items: center;
`;
