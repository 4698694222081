import styled from 'styled-components'
import * as palette from '../styles/variables'
import { width } from '../styles/device'


export const FooterStyled = styled.footer`
    background-color: ${palette.black};
    background-image: linear-gradient(135deg,#000 52%,#131212 32%,#000 100%);
    padding: 3rem 15px 2rem 15px;

    .container {
        max-width: ${width.sm};
        margin: 0 auto;
        text-align: center;

        .logo-footer {
            width: 150px;
        }
    }

    .gatsby-image-wrapper {
        margin: 0 auto;
        width: 90px;
    }

    .textWrap {
        text-align: center;
        color: ${palette.mediumGrey};
        font-size: ${palette.fontSizeXs};
        line-height: ${palette.lineHeightXs};
        margin-top: 1rem;

         p {
            margin-bottom: 1rem;
         }
    }

    .mga {
        width: 100px;
        display: block;
        margin: 0 auto;
    }

    .navFooter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        border-top: 1px solid ${palette.darkGrey};

        ul {
            padding-left: 0;
            margin-top: 1rem;
        }
        ul li {
            font-size: ${palette.fontSizeXs};
            display: inline-block;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            a {
                color: ${palette.mediumGrey} !important;
            }

        }
    }

`;

