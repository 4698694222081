import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device } from '../styles/device'


export const GoToTopStyled = styled.div`
    display: none;
    z-index: 299;
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: ${palette.greyTransparent};
    line-height: 36px;
    text-align: center;
    color: #fff;
    top: auto;
    left: auto;
    right: 30px;
    bottom: 50px;
    cursor: pointer;
    border-radius: 2px;
    transition: background-color .2s linear;
    -webkit-transition: background-color .2s linear;

    &.active {
        display: none;
    }

    @media ${device.tablet} {

            &.active {
            display: block;
        }
    }

    &::before {
        font-family: 'Font Awesome 5 Free';
        content: '\f077';
        font-size: 0.8rem;
    }

    &:hover {
        background-color: linear-gradient(120deg,hsl(283,94%,29%),#1068ec);
        background-image: linear-gradient(120deg,hsl(283,94%,29%),#1068ec);

    }
    @media (hover: none) {
        &:hover {
            background-color: ${palette.greyTransparent};
            background-image: none;
        }
    }



`;
