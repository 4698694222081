import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import {LayoutStyled} from './layoutStyled'
import Footer from './footer'
import GoToTop from './goToTop'
import Helmet from "react-helmet"



const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,900&display=swap" rel="stylesheet"></link>
      <link rel="stylesheet" href="https://use.typekit.net/sqr4juy.css"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.2/css/solid.min.css"></link>
    </Helmet>
      <LayoutStyled>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <Footer />
        <GoToTop />
      </LayoutStyled>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
