import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from 'react-markdown'
import Nav from './nav'
import {FooterStyled} from './footerStyled'



const Footer = () => {
    const data = useStaticQuery(graphql`
    query FooterQuery {
        allContentfulFooter {
            edges {
                node {
                  id
                  textFooter
                  text {
                    text
                  }
                  logo {
                    file {
                      url
                      fileName
                    }
                    sizes(resizingBehavior: SCALE) {
                        ...GatsbyContentfulSizes_withWebp
                    }
                  }
                  image {
                    file {
                      url
                      fileName
                    }
                  }
                }
              }
        }
    }
`)
if(!data.allContentfulFooter.edges) {
    return <div>Loading...</div>
} else {
    return data.allContentfulFooter.edges.map((edge) => {
        if(edge.node && edge.node.text && edge.node.logo && edge.node.logo.file && edge.node.image && edge.node.image.file) {
            return (
                <FooterStyled key={edge.node.id} id="footer">
                    <div className="container" id="footerContainer">
                        <img src={edge.node.logo && edge.node.logo.file.url} alt="Cubeia logo" className="logo-footer"/>
                        <div className="textWrap">
                            <Markdown source={edge.node.text.text} />
                        </div>
                        <a href="https://www.authorisation.mga.org.mt/verification.aspx?lang=EN&amp;company=ad150213-5d02-460f-a8c6-c655273d53ce&amp;details=1" target="_blank" rel="noopener noreferrer">
                            <img className="mga" src={edge.node.image.file.url} alt={edge.node.image.file.fileName} />
                        </a>
                        <div className="navFooter">
                            <Nav />
                        </div>
                    </div>
                </FooterStyled>
            )
        }
        return true;
    })
}
}
 export default Footer

