import { useState, useEffect } from "react"
import {addEventScroll} from './scrollX'


const ScrollHook = () => {
    const [scroll, setScroll] = useState(0)

    useEffect(() => {

        addEventScroll(handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
    }, [])

    const handleScroll = () => {
        const winScroll = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
        setScroll(winScroll)
    }

    return scroll

}

 export default ScrollHook

