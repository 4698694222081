import React from 'react'
import PropTypes from "prop-types"
import {BurgerAnimateStyled} from './burgerAnimateStyled'


const BurgerAnimate = ({isClickedBurger, onClick}) => {
    const burger = isClickedBurger ? 'burger-icon-animate open' : 'burger-icon-animate'
    return (
        <BurgerAnimateStyled className={burger} onClick={onClick}>
            <span></span>
            <span></span>
            <span></span>
        </BurgerAnimateStyled>
    )
}

BurgerAnimate.propTypes = {
    isClickedBurger: PropTypes.bool,
    onClick: PropTypes.func
}


export default BurgerAnimate